import React from 'react'
import './Footer.css'
import shapebg from '../../../assets/Home/shape-bg.png'
export const  Footer=()=> {
  return (
    <div className='footer-container'>
        <div className='footer-parent'>
        <img src={shapebg} alt="shape background"/>
        </div>
    </div>
  )
}